import { FlowBase, identificationTypes } from "./flow-base";

class MTNRWTwoClickOTPFlow extends FlowBase {
    handleIntegratorIframeIdentity() {

        //since heIframeIdentity has no error handled add a timeout then call identifyOnError
        this.iframeTimeout = window.setTimeout(() => {
            this.logger.info('[MTN RW] Iframe timed out.')
            this.checkIdentify()
        }, this.identifyTimeout * 1000);

        this.integrator.customHeIframeIdentifyBaseUrl(this.identifyEndpoint,
            {
                return: window.location.href,
            }
        );
    }
}

const MTNRWTwoClickOTPFlowModule = new MTNRWTwoClickOTPFlow('Twoclick MTN RW', identificationTypes.IFRAME, {
    subscribeClick: true,
    OTP: true,
    otpChallengeValidateURL: 'rw/otp/subscribe',
    optChallengeSubmitURL: 'rw/otp/generate',
    consentTickbox: true,
    consentTickboxAutofilled: true,
    inactiveTimeout: true,
    subscribeEndpoint: 'rw/subscribe',
    identifyEndpoint: 'rw/he/identify',
    identifyTimeout: 20,
})
window.FlowModule = MTNRWTwoClickOTPFlowModule;
